<template>
  <div>
    <!-- <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-menu
              v-model="invocingDateMenu"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Számlázás időpontja"
                  readonly
                  :value="invoicingDateDisp"
                  v-on="on"
                ></v-text-field>
              </template>
              <!-- :max= -->
              <v-date-picker
                locale="hu"
                v-model="invoicingDate"
                v-on:change="dateOnChange"
                no-title
                @input="invocingDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-card-title>

          <v-card-text>
            <v-data-table
              dense
              :headers="tableHeaders"
              :items="notGeneratedInvoices"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
            >
              <template v-slot:item.client_id="{ item }">
                <a
                  target="_blank"
                  :href="'https://ucrm.keviwlan.hu/client/' + item.client_id"
                  >{{ item.client_id }}</a
                >
              </template>
              <template v-slot:item.client_name="{ item }">
                {{ item.client_name }}
                <v-chip v-for="tag in item.client_tags" :key="tag" x-small>
                  {{ tag }}
                </v-chip>
              </template>
              <template v-slot:item.client_address="{ item }">
                {{ item.client_zip_code }}, {{ item.client_city }},
                {{ item.client_street1 }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { API } from "../../../api";
export default {
  name: "noinvoices.index",
  data: () => ({
    loading: true,
    invoicingDate: new Date(),
    invocingDateMenu: false,
    notGeneratedInvoices: [],
    tableHeaders: [
      {
        text: "Ügyfél ID",
        value: "client_id"
      },
      {
        text: "Ügyfél neve",
        value: "client_name"
      },
      {
        text: "Cím",
        value: "client_address"
      }
    ],
    tableFooter: {
      "items-per-page-options": [25, 50, 100, -1]
    },
    options: {}
  }),
  created: function() {
    this.invoicingDate.setDate(1);
    this.invoicingDate = this.invoicingDate.toISOString().split("T")[0];
    this.$store.commit("app/setTitle", "Nem készült számla");
  },
  computed: {
    invoicingDateDisp() {
      return this.invoicingDate;
    }
  },
  mounted: function() {
    API.instance
      .get(`ucrm/not_generated_invoices?invoicing_date=${this.invoicingDate}`)
      .then(response => {
        this.notGeneratedInvoices = response.data;
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false));
  },
  methods: {
    dateOnChange: function() {
      this.loading = true;
      API.instance
        .get(`ucrm/not_generated_invoices?invoicing_date=${this.invoicingDate}`)
        .then(response => {
          this.notGeneratedInvoices = response.data;
        })
        .catch(err => console.log(err))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
