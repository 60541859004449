var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Számlázás időpontja","readonly":"","value":_vm.invoicingDateDisp}},on))]}}]),model:{value:(_vm.invocingDateMenu),callback:function ($$v) {_vm.invocingDateMenu=$$v},expression:"invocingDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"hu","no-title":""},on:{"change":_vm.dateOnChange,"input":function($event){_vm.invocingDateMenu = false}},model:{value:(_vm.invoicingDate),callback:function ($$v) {_vm.invoicingDate=$$v},expression:"invoicingDate"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.notGeneratedInvoices,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":'https://ucrm.keviwlan.hu/client/' + item.client_id}},[_vm._v(_vm._s(item.client_id))])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client_name)+" "),_vm._l((item.client_tags),function(tag){return _c('v-chip',{key:tag,attrs:{"x-small":""}},[_vm._v(" "+_vm._s(tag)+" ")])})]}},{key:"item.client_address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client_zip_code)+", "+_vm._s(item.client_city)+", "+_vm._s(item.client_street1)+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }